import type { Thunk } from '@/bootstrap/thunks';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ExecFees } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { createZodObject } from '@/util/zod/zod-util';
import { z } from 'zod';
import { fromError } from 'zod-validation-error';
import { camelCase } from 'lodash';
import { parseValidExcelNumber } from '@/util/number/numberUtils.ts';

const ExecFeesSchema = createZodObject<ExecFees>({
  value: z.number().optional(),
  unit: z.union([z.literal('bp'), z.literal('%'), z.literal('Cts')]).optional(),
  type: z.union([z.literal('BIPS'), z.literal('REF_PERCENT'), z.literal('CENTS')]).optional(),
});

const ImportedLineSchema = z.object({
  bloombergCode: z.string().min(1),
  quantity: z.string().min(1).transform(transformNumberIfDefined),
  spot: z.string().optional().transform(transformNumberIfDefined),
  spotUnit: z.string().optional(),
  spotNet: z.string().optional().transform(transformNumberIfDefined),
  spotNetUnit: z.string().optional(),
  execFeesIn: z.string().optional().transform(transformNumberIfDefined),
  execFeesInUnit: ExecFeesSchema.shape.unit.optional(),
  execFeesOut: z.string().optional().transform(transformNumberIfDefined),
  execFeesOutUnit: ExecFeesSchema.shape.unit.optional(),
});

const RawImportedDataSchema = z.array(z.record(z.string(), z.string()));

const ImportedDataSchema = z.array(ImportedLineSchema);
export type ValidImportedBasketData = z.output<typeof ImportedDataSchema>;

export function createImportBasketCompositionThunk(
  rfqId: string,
  productId: string,
  rawImportedData: unknown[],
): Thunk {
  return function importBasketCompositionThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }
    const rawParsingResult = RawImportedDataSchema.safeParse(rawImportedData);

    if (!rawParsingResult.success) {
      const zodError = fromError(rawParsingResult.error, {
        prefix: 'Error trying to parse imported basket composition',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const importedDataWithCamelCaseKeys: Record<string, string>[] = rawParsingResult.data.map(
      line =>
        Object.fromEntries(
          Object.entries(line).map(([key, value]) => {
            return [camelCase(key), value];
          }),
        ),
    );

    const approximatedImportedData = approximateImportedKeys(importedDataWithCamelCaseKeys);

    const parsingResult = ImportedDataSchema.safeParse(approximatedImportedData);

    if (!parsingResult.success) {
      const zodError = fromError(parsingResult.error, {
        prefix: 'Error trying to parse imported basket composition',
      }).toString();

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validatedData = parsingResult.data as ValidImportedBasketData;

    const importedBloombergCodes = validatedData.map(line => line.bloombergCode);
    const areSomeDuplicatedBloombergCodes = importedBloombergCodes.some((item, index) => {
      return importedBloombergCodes.indexOf(item) !== index;
    });

    if (areSomeDuplicatedBloombergCodes) {
      const message =
        'Error trying to import basket composition: Some Bloomberg codes are duplicated.';
      dispatch(actionCreators.common.createLogAction(message, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message,
          },
          undefined,
        ),
      );
      return;
    }

    function isImportedLineBloombergCodeDefined(
      line: ValidImportedBasketData[number],
    ): line is ValidImportedBasketData[number] {
      return line.bloombergCode !== undefined;
    }

    const definedData = validatedData.filter(isImportedLineBloombergCodeDefined);

    if (definedData.length > 0) {
      dispatch(
        actionCreators.neos.createBasketUnderlyingIdsRequestedAction(
          rfqId,
          product.uuid,
          definedData,
        ),
      );
    }
  };
}

function transformNumberIfDefined(
  value: string | undefined,
  ctx: z.RefinementCtx,
): number | undefined {
  if (value === undefined) {
    return value;
  }

  const parsedValue = parseValidExcelNumber(value);
  if (parsedValue === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Not a number',
    });

    // This is a special symbol used to return early from the transform function.
    // It has type `never` so it does not affect the inferred return type.
    return z.NEVER;
  }
  return parsedValue;
}

function approximateImportedKeys(
  importedDataWithCamelCaseKeys: Record<string, string>[],
): Record<string, string>[] {
  return importedDataWithCamelCaseKeys.map(line => {
    return Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        const column = key.toLowerCase();
        if (column === 'bbg' || column === 'bloom') {
          return ['bloombergCode', value];
        }
        if (column === 'qty') {
          return ['quantity', value];
        }

        return [key, value];
      }),
    );
  });
}
