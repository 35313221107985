import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';

import { useAppSelector } from '@/bootstrap/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

interface PortfolioProps {
  strategyId: string;
  rfqId: string;
  isComponentInLegDescription?: boolean;
}

export function Portfolio({
  strategyId,
  rfqId,
  isComponentInLegDescription = false,
}: PortfolioProps) {
  const dispatch = useDispatch();
  const { portfolioId } = useAppSelector(state => selectors.getStrategyData(state, strategyId));
  const { internal } = useAppSelector(state => selectors.getRfqData(state, rfqId));

  const [portfolioIdValue, setPortfolioIdValue] = useState(portfolioId);

  useEffect(() => {
    setPortfolioIdValue(portfolioId);
  }, [portfolioId]);

  const onPortfolioIdChanged = (value: string | undefined) => {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { portfolioId: value }));
  };

  const isReadOnlyRfq = useAppSelector(state => selectors.isReadOnlyRfq(state, rfqId));
  const isReadOnlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return (
    <>
      <label className={isComponentInLegDescription ? 'mb-0 me-3' : ''}>
        {internal ? 'Initiator Deal Ptf' : 'Portfolio'}
      </label>
      <input
        readOnly={isReadOnlyRfq || isReadOnlyAtCurrentWorkflow}
        className={`form-control ${isComponentInLegDescription ? '' : 'form-control-alt'}`}
        data-e2e="strategy-details-portfolio"
        value={portfolioIdValue ?? ''}
        type="text"
        onChange={e => setPortfolioIdValue(e?.target?.value.toUpperCase())}
        onBlur={event => onPortfolioIdChanged(event.target.value.toUpperCase() || undefined)}
      />
    </>
  );
}
