import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import { availableExecFeesValues, type ElsBasket } from '@/neos/business/neosModel';
import type { Market } from '@/neos/business/referenceData/markets/marketsModel';
import { availableForexTypes } from '@/neos/business/rfq/strategy/feature/elsSections/elsSectionsModel';
import { BasketSpotConfirmedCheckbox } from '@/neos/components/rfq/strategies/strategy/features/elsBasket/BasketSpotConfirmedCheckbox';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { NeosSelectWithAutocomplete } from '@/neos/components/share/neosSelectWithAutocomplete/NeosSelectWithAutocomplete';
import { NumericInput } from '@/neos/components/share/numericInput';
import { Switch } from '@/neos/components/share/switch/Switch';
import { formatFromCamelToStartCase } from '@/util/format';
import { useDispatch } from 'react-redux';

interface ElsBasketDescriptionProps {
  rfqId: string;
  strategyId: string;
  product: ElsBasket;
}

const typologies = {
  CASH_MANAGEMENT: 'Cash Management',
  CORPORATE_ACTION: 'Corporate Action',
  FOREX_OPTIONS: 'Forex Options',
  FUNDS_UNITS: 'Funds Units',
  PARAM_CALIBRATION: 'Param Calibration',
  MULTI_UNDERLYING: 'Multi-Underlying',
} as const;

export function ElsBasketDescription({ product, rfqId }: ElsBasketDescriptionProps) {
  const dispatch = useDispatch();
  const currencies = useAppSelector(state => selectors.getCurrencyList(state.referenceData));
  const marketsEliot = useAppSelector(state => selectors.getEliotMarkets(state.referenceData));
  const isRfqReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isRfqReadOnlyAtCurrentWorkflow(state, rfqId, selectors),
  );

  return (
    <div
      className="mb-2 d-grid gap-1 text-end"
      style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}
    >
      <label className="p-1">Eliot Id</label>
      <NeosBlurInput
        readOnly={isRfqReadonlyAtCurrentWorkflow}
        value={product.basketUnderlying.basketEliotID}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsBasketThunk(
              product.uuid,
              'basketEliotID',
              event.target.value,
            ),
          );
        }}
        maxLength={8}
        data-e2e="els-basket-eliot-id"
      />

      <label className="p-1">Currency</label>
      <NeosSelect
        value={product.basketUnderlying.currency}
        onChange={value => {
          dispatch(thunks.neos.createUpdateElsBasketThunk(product.uuid, 'currency', value));
          dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
          dispatch(thunks.neos.createDefaultOnChangeBasketCurrencyThunk(rfqId));
        }}
        data-e2e="els-basket-currency"
        addEmptyOption
        options={currencies.map(({ refId }) => ({ value: refId, label: refId }))}
      />

      <Switch
        checked={product.basketUnderlying?.isAutoDiv ?? false}
        onChange={() => {
          dispatch(
            thunks.neos.createUpdateElsBasketThunk(
              product.uuid,
              'isAutoDiv',
              !product.basketUnderlying.isAutoDiv,
            ),
          );
        }}
        e2eId="els-basket-auto-div"
        labelName="Auto Div"
      />

      <label className="p-1">Market</label>
      <NeosSelectWithAutocomplete<Market>
        value={product.basketUnderlying?.basketMarket?.galaxyCode}
        onChange={market => {
          const oldBasketMarket = product.basketUnderlying?.basketMarket ?? {
            galaxyCode: undefined,
            micCode: undefined,
          };
          dispatch(
            thunks.neos.createUpdateElsBasketThunk(product.uuid, 'basketMarket', {
              ...oldBasketMarket,
              galaxyCode: market?.value,
            }),
          );
          dispatch(thunks.neos.changeElsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
        }}
        data-e2e="els-basket-market"
        addEmptyOption
        options={marketsEliot
          .filter(market => market.galaxyCode !== null)
          .map(market => ({
            value: market.galaxyCode ?? 'n/a',
            label: market.galaxyCode + ` (${market.micCode})` ?? 'n/a',
          }))}
      />

      <label className="p-1">Forex Type</label>
      <NeosSelect
        value={product.basketUnderlying.forexType}
        onChange={value => {
          dispatch(thunks.neos.createUpdateElsBasketThunk(product.uuid, 'forexType', value));
        }}
        data-e2e="els-basket-forex-type"
        addNonSelectableEmptyOption
        options={availableForexTypes.map(forexType => ({
          value: forexType,
          label: forexType ? formatFromCamelToStartCase(forexType) : '',
        }))}
      />

      <Switch
        checked={product.basketUnderlying?.isAutoRepo ?? false}
        onChange={() => {
          dispatch(
            thunks.neos.createUpdateElsBasketThunk(
              product.uuid,
              'isAutoRepo',
              !product.basketUnderlying.isAutoRepo,
            ),
          );
        }}
        e2eId="els-basket-auto-repo"
        labelName="Auto Repo"
      />

      <label className="p-1">Typology</label>
      <NeosSelect
        value={product.basketUnderlying.basketTypology}
        onChange={value => {
          dispatch(thunks.neos.createUpdateElsBasketThunk(product.uuid, 'basketTypology', value));
        }}
        data-e2e="els-basket-typology"
        addEmptyOption
        options={typologies}
      />

      <label className="p-1">Repo source</label>
      <NeosBlurInput
        value={product.basketUnderlying.repoSource}
        onBlur={event => {
          dispatch(
            thunks.neos.createUpdateElsBasketThunk(product.uuid, 'repoSource', event.target.value),
          );
        }}
        data-e2e="els-basket-repo-source"
      />
      <BasketSpotConfirmedCheckbox product={product} rfqId={rfqId} />

      <label className="p-1">Multiplier</label>
      <NumericInput
        readOnly
        value={product.basketUnderlying.basketMultiplier}
        data-e2e="els-basket-multiplier"
      />

      <label className="p-1">Divisor</label>
      <NumericInput
        readOnly
        value={product.basketUnderlying.basketDivisor}
        data-e2e="els-basket-divisor"
      />

      <span></span>

      <label className="p-1">Exec Fees</label>
      <NeosSelect
        value={product.basketUnderlying.execFees}
        onChange={value => {
          dispatch(thunks.neos.createChangeElsBasketExecFeeKindThunk(rfqId, product.uuid, value));
        }}
        data-e2e="els-basket-exec-fees"
        options={availableExecFeesValues.map(value => ({ label: value, value }))}
      />
    </div>
  );
}
